<script>
  import MainLayout from "layouts/MainLayout.svelte";
  import { _ } from "svelte-i18n";
  import Banner from "./banner/Banner.svelte";
  import anime from "animejs/lib/anime.es.js";
  import { onMount } from "svelte";
  import ServicesSection from "./our-services/ServicesSection.svelte";
  import ContactUsSection from "./contact-us/ContactUsSection.svelte";
  // import BaseModal from "../../components/modals/BaseModal.svelte";
  import PartnersSection from "./partners-and-clients/PartnersSection.svelte";
  // let show = true;
  onMount(() => {
    // setTimeout(() => {
      // show = false;
    // }, 5000);
    anime({
      targets: ".logo-brand-bigger",
      opacity: [0, 1],
      translateY: [-10, 0],
      easing: "linear",
      duration: 800,
    });
  });
</script>

<MainLayout>
  <div slot="content">
    <!-- Sementara dinonaktifkan dulu
      
      <BaseModal
      show={show}
      onClick={() => {
        show = fasle;
      }}
    /> -->
    <Banner />
    <ServicesSection />
    <section class="simple-cta">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 offset-lg-1">
            <div class="left-image">
              <img
                src="/assets/images/agrobook-banner.webp"
                alt="Hunayn Agrobook Mockup"
                style="width:100%; height:auto; aspect-ratio:1/1;"
              />
            </div>
          </div>
          <div class="col-lg-5 align-self-center">
            <h6>{$_("lets_build_with_us")}</h6>
            <h4>{$_("ready_to_get_started")}</h4>
            <p>
              {@html $_("just_prepare_your_idea")}
            </p>
            <div class="white-button">
              <a href="#contact-section">{$_("contact_us")}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <PartnersSection />
    <ContactUsSection />
  </div>
</MainLayout>
