<script>
  import { onMount, onDestroy } from "svelte";
  import { slide } from "svelte/transition";
  import { headerMenu } from "config";
  import anime from "animejs";
  import { _, locale, getLocaleFromNavigator } from "svelte-i18n";

  const scrollNavBar = 60;
  let show = false;
  let isMenuActive = false;

  onMount(() => {
    window.onscroll = () => {
      if (window.scrollY > scrollNavBar) {
        show = true;
      } else {
        show = false;
      }
    };
    anime({
      targets: ".widenav",
      opacity: [0, 1],
      translateY: [-20, 0],
      easing: "easeInOutExpo",
      duration: 800,
    });
  });
  onDestroy(() => {
    window.onscroll = () => {};
  });

  function onMenuClick() {
    isMenuActive = !isMenuActive;
  }
  let selectedLocale = getLocaleFromNavigator().split("-")[0];
</script>

<header class="header-area header-sticky">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <nav class="main-nav">
          <!-- ***** Logo Start ***** -->
          <a href="/" class:scrolled={show} class="logo logo-wide-screen">
            <img
              class="logo-brand-bigger"
              src="/assets/images/hunayn-horizontal-logo.webp"
              alt="Hunayn Tech Logo"
            />
          </a>
          <a href="/" class:scrolled={show} class="logo logo-narrow-screen">
            <img
              class="logo-brand-bigger"
              src="/assets/images/hunayn-horizontal-logo-white.webp"
              alt="Hunayn Tech Logo"
            />
          </a>
          <a href="/" class:scrolled={!show} class="logo">
            <img
              class="logo-brand"
              src="/assets/images/hunayn-horizontal-logo-white.webp"
              alt="Hunayn Tech Logo"
            />
          </a>
          <!-- ***** Logo End ***** -->
          <!-- ***** Menu Start ***** -->
          <ul
            class="nav"
            class:collapsed={isMenuActive}
            class:narrownav={show}
            class:widenav={!show}
          >
            {#each headerMenu as menu, index}
              <li
                class:scroll-to-section={!menu.subMenus}
                class:has-sub={menu.subMenus}
              >
                {#if menu.subMenus}
                  <a href={null}>{$_(menu.nameTx)}</a>
                  <ul
                    class="sub-menu"
                    class:last-menu={index == headerMenu.length - 1}
                  >
                    {#each menu.subMenus as subMenu}
                      {#if subMenu.url}
                        <li><a href={subMenu.url}>{$_(subMenu.nameTx)}</a></li>
                      {:else}
                        <li>
                          <a
                            href={null}
                            on:click={() => {
                              locale.set(subMenu.locale);
                            }}>{$_(subMenu.nameTx)}</a
                          >
                        </li>
                      {/if}
                    {/each}
                  </ul>
                {:else}
                  <a href={menu.url} class="active">{$_(menu.nameTx)}</a>
                {/if}
              </li>
            {/each}
          </ul>
          {#if isMenuActive}
            <ul
              class="nav"
              transition:slide
              class:narrownav={show}
              class:widenav={!show}
              class:d-none={!isMenuActive}
              class:d-block={isMenuActive}
            >
              {#each headerMenu as menu}
                <li
                  class:scroll-to-section={!menu.subMenus}
                  class:has-sub={menu.subMenus}
                  class="is-open-sub"
                >
                  {#if menu.subMenus}
                    <a href={null}>{$_(menu.nameTx)}</a>
                    <ul class="sub-menu">
                      {#each menu.subMenus as subMenu}
                        {#if subMenu.url}
                          <li>
                            <a href={subMenu.url}>{$_(subMenu.nameTx)}</a>
                          </li>
                        {:else}
                          <li>
                            <a
                              href={null}
                              on:click={() => locale.set(subMenu.locale)}
                              >{$_(subMenu.nameTx)}</a
                            >
                          </li>
                        {/if}
                      {/each}
                    </ul>
                  {:else}
                    <a href={menu.url} class="active">{$_(menu.nameTx)}</a>
                  {/if}
                </li>
              {/each}
            </ul>
          {/if}
          <a
            href={"#"}
            class:active={isMenuActive}
            on:click={onMenuClick}
            class="menu-trigger"
          >
            <span>Menu</span>
          </a>
          <!-- ***** Menu End ***** -->
        </nav>
      </div>
    </div>
  </div>
</header>

<style>
  .scrolled {
    display: none !important;
  }

  .logo-narrow-screen {
    display: none;
  }

  @media (max-width: 1200px) {
    .logo-wide-screen {
      display: none;
    }
    .logo-narrow-screen {
      display: block;
    }
    .header-area .main-nav .nav {
      margin-top: 30px;
    }
    .header-area .main-nav .nav li.has-sub {
      padding-right: 14px;
    }
    .header-area .main-nav .nav li.has-sub:after {
      right: 3px;
    }
    .widenav {
      margin-top: 30px !important;
    }

    .narrownav {
      margin-top: 15px !important;
    }
  }
  li .locale-active {
    background-color: aquamarine;
  }
  .last-menu {
    transform: translateX(-20%) !important;
  }
</style>
