<script>
  import MainLayout from "layouts/MainLayout.svelte";
  import PageHeader from "components/page-header/PageHeader.svelte";
  import { _ } from "svelte-i18n";


</script>

<MainLayout>
    <div slot="content">
      <PageHeader
      subtitle={$_("products_header_subtitle")} 
      title={$_("products_header_title")}
      />
      <section>
        <div class="container">
          <div class="row">
            <div class="col-lg-5 img-fluid">
              <img src="/assets/images/dashboard-syirkah.png" alt="" />
            </div>
            <div class="col-lg-7">
              <h2>Syirkah by Hunayn</h2>
              <h5 class="mt-4">
                "The First Syariah Cooperative and Financing Software as a Service."<br/>
                Syirkah is a Software as a Service that supports your Syariah Financing Business. Start from Mudharabah, Murabahah, Ijaroh System All in one stop solution. 
                Adopt Syirkah for more efficient and easeness workflow in your business.
              </h5>
              <h5 class="mt-4">
                <a href="mailto:sales@hunayn.tech">Request Demo</a>
                <!-- <a href="https://syirkah.stg.hyn.cat/" target="_blank" rel="noreferrer">Request Demo</a> -->
              </h5>
            </div>
        </div>
      </section>
    </div>
</MainLayout>

<style>
  section {
      padding: 50px;
      margin-top: 20px;
  }
  h1 {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 700;
  }
  h5 {
    font-family: "Raleway", sans-serif;
    font-weight: 300;
  }

</style>