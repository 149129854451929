<script>
  import Carousel from "svelte-carousel";
  import { onMount } from "svelte";

  let a = 4;
  let b = 2;
  onMount(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width < 780 && width > 601) {
        a = 3;
        b = 1;
      }
      if (width < 600) {
        a = 1;
        b = 1;
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
</script>

<Carousel
  particlesToShow={a}
  particlesToScroll={b}
  autoplay
  autoplayDuration={3000}
  dots={false}
>
  <div class="container-carousel">
    <img
      src="/assets/images/microsoft.svg"
      alt="microsoft"
      class="img-carousel"
      style="width:auto; height:80px; aspect-ratio:auto;"
    />
  </div>
  <div class="container-carousel">
    <img
      src="/assets/images/pax8-logo-black.svg"
      alt="pax8"
      class="img-carousel"
      style="width:auto; height:80px; aspect-ratio:auto;"
    />
  </div>
  <!-- <div class="container-carousel">
    <img
      src="/assets/images/firebase.svg"
      alt="firebase"
      class="img-carousel"
      style="width:auto; height:80px; aspect-ratio:auto;"
    />
  </div> -->
  <!-- <div class="container-carousel">
    <img
      src="/assets/images/zoho.svg"
      alt="zoho"
      class="img-carousel"
      style="width:auto; height:80px; aspect-ratio:auto;"
    />
  </div> -->
  <div class="container-carousel">
    <img
      src="/assets/images/m3.png"
      alt="mumtazah"
      class="img-carousel"
      style="width:auto; height:80px; aspect-ratio:auto;"
    />
  </div>
  <!-- <div class="container-carousel">
    <img
      src="/assets/images/gatsby.svg"
      alt="gatsby"
      class="img-carousel"
      style="width:auto; height:80px; aspect-ratio:auto;"
    />
  </div> -->
  <div class="container-carousel">
    <img
      src="/assets/images/strapi.svg"
      alt="strapi"
      class="img-carousel"
      style="width:auto; height:80px; aspect-ratio:auto;"
    />
  </div>
  <div class="container-carousel">
    <img
      src="/assets/images/odoo_learning_partner_rgb.webp"
      alt="odoo learning partner"
      class="img-carousel"
      style="width:auto; height:80px; aspect-ratio:auto;"
    />
  </div>
</Carousel>

<style>
  .container-carousel {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    text-align: center;
  }

  .img-carousel {
    max-width: 100%;
    max-height: 100px;
    object-fit: scale-down;
    padding-right: 15px;
    padding-left: 15px;
  }

  .container-carousel:hover .img-carousel {
    opacity: 1;
  }

  .container-carousel .img-carousel {
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
  }

  .img-carousel:hover {
    opacity: 1;
  }

  @media only screen and (max-width: 600px) {
    .container-carousel {
      display: flex !important;
      overflow: hidden !important;
      width: calc(100% * 2) !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      align-self: center !important;
      text-align: center !important;
    }

    .img-carousel {
      flex-shrink: 0;
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .img-carousel:first-of-type {
      margin-left: 0 !important;
    }

    .img-carousel:last-of-type {
      margin-right: 0 !important;
    }
  }
</style>
