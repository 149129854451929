<script>
  import { Router, Route } from "svelte-navigator";
  import LinkPage from "./LinkPage.svelte";
  import HomePage from "pages/home/HomePage.svelte";
  import CareerPage from "pages/career/CareerPage.svelte";
  import ProductsPage from "./pages/products/ProductsPage.svelte";
  import { locale } from "config";
  import { onMount } from "svelte";

  import { addMessages, init, getLocaleFromNavigator } from "svelte-i18n";
  addMessages("en", locale.en);
  addMessages("id", locale.id);
  init({
    fallbackLocale: "id",
    initialLocale: getLocaleFromNavigator(),
  });
  
</script>

<Router>
  <Route path="/links">
    <LinkPage />
  </Route>
  <Route path="/"><HomePage /></Route>
  <Route path="/careers"><CareerPage /></Route>
  <Route path="/products"><ProductsPage/></Route>
</Router>
