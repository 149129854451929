<script>
  import { contactInfo } from "config";
  import { _ } from "svelte-i18n";
  import emailjs from "@emailjs/browser";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  let name = "";
  let email = "";
  let message = "";
  let showModal = false;

  function closeModal() {
    showModal = false;
    name = "";
    email = "";
    message = "";
  }

  function sendEmail(e) {
    emailjs
      .sendForm(
        "default_service",
        "template_mwxhqme",
        e.target,
        "mLdF8fHEChtr74JM-"
      )
      .then(
        (result) => {
          showModal = true;
          setTimeout(() => {
            showModal = false;
            name = "";
            email = "";
            message = "";
          }, 3000);
        },
        (error) => {
          console.log("Failed ...", error.text);
        }
      );
  }
</script>

<section class="contact-us" id="contact-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <div id="map">
          <!-- You just need to go to Google Maps for your own map point, and copy the embed code from Share -> Embed a map section -->
          <iframe
            title="Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.7219723676426!2d106.6497548147695!3d-6.300216895440266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fb4a9ab4c0a9%3A0x8c280a9ab09aebc0!2sJl.%20BSD%20Green%20Office%20Park%2C%20Sampora%2C%20Kec.%20Cisauk%2C%20Kabupaten%20Tangerang%2C%20Banten%2015345!5e0!3m2!1sid!2sid!4v1677557884813!5m2!1sid!2sid"
            width="100%"
            height="420px"
            frameborder="0"
            style="border:0; border-radius: 15px; position: relative; z-index: 2;"
            allowfullscreen=""
          />

          <div class="row">
            <div class="col-lg-4 offset-lg-1">
              <div class="contact-info">
                <div class="icon">
                  <img
                    src="./assets/images/phone.svg"
                    alt="icon-phone"
                    style="acpect-ratio:1/1; padding:10px"
                  />
                </div>
                <h4>Phone</h4>
                <span>{contactInfo.phone}</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="contact-info">
                <div class="icon">
                  <img
                    src="./assets/images/envelope.svg"
                    alt="icon-phone"
                    style="acpect-ratio:1/1; padding:10px"
                  />
                </div>
                <h4>E-mail</h4>
                <span>{contactInfo.email}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <form id="contact" on:submit|preventDefault={sendEmail}>
          <div class="row">
            <div class="col-lg-12">
              <div class="section-heading">
                <h6>{$_("contact_us")}</h6>
                <h4>{@html $_("contact_form_heading")}</h4>
                <p>
                  {@html $_("contact_form_description")}
                </p>
              </div>
            </div>
            <div class="col-lg-12">
              <fieldset for="from_name">
                <input value="6285171571570" name="phone" type="hidden" />
                <input
                  type="name"
                  name="from_name"
                  id="from_name"
                  placeholder="Full Name"
                  autocomplete="on"
                  bind:value={name}
                  required
                />
              </fieldset>
            </div>
            <div class="col-lg-12">
              <fieldset for="from_email">
                <input
                  type="text"
                  name="from_email"
                  id="from_email"
                  pattern="[^ @]*@[^ @]*"
                  placeholder="Your Email"
                  bind:value={email}
                  required=""
                />
              </fieldset>
            </div>
            <div class="col-lg-12">
              <fieldset for="message">
                <textarea
                  name="message"
                  id="message_html"
                  bind:value={message}
                  placeholder="Your Message"
                />
              </fieldset>
            </div>
            <div class="col-lg-12">
              <fieldset>
                <button type="submit" id="contact-submit" value="submit"
                  >Submit</button
                >
              </fieldset>
            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-12">
        <ul class="social-icons">
          <li>
            <a href="https://fb.me/hunayn"
              ><img
                src="./assets/images/facebook-original.svg"
                alt="facebook-icon"
                style="aspect-ratio:1/1; padding:2px; width:50%; height:50%; display:inline; justify-content:center;"
              /></a
            >
          </li>
          <li>
            <a href="https://linkedin.com/company/hunayn"
              ><img
                src="./assets/images/linkedin-original.svg"
                alt="linkedin-icon"
                style="aspect-ratio:1/1; padding:2px; width:50%; height:50%; display:inline; justify-content:center;"
              /></a
            >
          </li>
          <li>
            <a href="https://instagram.com/hunayn.tech"
              ><img
                src="./assets/images/instagram-original.svg"
                alt="instagram-icon"
                style="aspect-ratio:1/1; padding:2px; width:50%; height:50%; display:inline; justify-content:center;"
              /></a
            >
          </li>
          <li>
            <a href="https://github.com/hunayntech"
              ><img
                src="./assets/images/github-original.svg"
                alt="github-icon"
                style="aspect-ratio:1/1; padding:2px; width:50%; height:50%; display:inline; justify-content:center;"
              /></a
            >
          </li>
        </ul>
      </div>
      <div class="col-lg-12">
        <p class="copyright">
          Copyright © 2022 Hunayn Technologies, All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</section>

{#if showModal}
  <div class="modal">
    <div class="modal-content">
      <span class="close" on:click={() => closeModal}>&times;</span>
      <h4>Email sent successfully!</h4>
      <img
        class="mt-4"
        src="/assets/images/reshot-illustration-email.webp"
        alt="email-modal-illustration"
        style="width: 100%; height: auto; aspect-ratio:auto;"
      />
    </div>
  </div>
{/if}

<style>
  /* ... */
  .modal {
    display: block;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .modal-content {
    background-color: white;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
    height: auto;
    text-align: center;
  }

  .close {
    text-align: right;
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin-right: 10px;
    cursor: pointer;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
</style>
