<svelte:head>
  <meta
    name="description"
    content="Hunayn is building digital ecosystems by providing technologycal services to our life."
  />
  <meta
    name="keywords"
    content="Hunayn,tech,Jasa Pembuatan Software,jasa pembuatan aplikasi,software house,perusahaan teknologi"
  />
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width,initial-scale=1" />
  <title>Hunayn Tech - Links</title>
  <link rel="icon" type="image/png" href="/favicon.png" />
  <link rel="stylesheet" href="/global.css" />
  <link rel="stylesheet" href="/build/bundle.css" />

  <link
    rel="stylesheet"
    type="text/css"
    href="/app-assets/vendors/css/vendors.min.css"
  />

  <link rel="stylesheet" type="text/css" href="/app-assets/css/bootstrap.css" />
  <link
    rel="stylesheet"
    type="text/css"
    href="/app-assets/css/bootstrap-extended.css"
  />
  <link rel="stylesheet" type="text/css" href="/app-assets/css/colors.css" />
  <link
    rel="stylesheet"
    type="text/css"
    href="/app-assets/css/components.css"
  />
  <link
    rel="stylesheet"
    type="text/css"
    href="/app-assets/css/themes/dark-layout.css"
  />
  <link
    rel="stylesheet"
    type="text/css"
    href="/app-assets/css/themes/bordered-layout.css"
  />
  <link
    rel="stylesheet"
    type="text/css"
    href="/app-assets/css/themes/semi-dark-layout.css"
  />

  <link
    rel="stylesheet"
    type="text/css"
    href="/app-assets/css/core/menu/menu-types/horizontal-menu.css"
  />
  <link
    rel="stylesheet"
    type="text/css"
    href="/app-assets/css/pages/page-misc.css"
  />
  <link rel="stylesheet" type="text/css" href="/assets/css/style.css" />

  <script
    type="text/javascript"
    src="https://livechat.messagebird.com/bootstrap.js?widgetId=f1f0b66d-6da0-4e0d-a4f8-f505a3584ed3"
    async=""
    id="live-chat-widget-script"></script><script
    async=""
    src="https://www.googletagmanager.com/gtag/js?id=G-7X9KC760ST"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-7X9KC760ST");
  </script>
  <link
    rel="stylesheet"
    type="text/css"
    href="/app-assets/css/pages/authentication.css"
  />
</svelte:head>

<slot name="content" />
