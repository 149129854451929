<script>
  import Header from "components/header/Header.svelte";
</script>

<svelte:head>
  <link
    href="/assets/vendor/bootstrap/css/bootstrap.min.css"
    rel="stylesheet"
  />
  <!-- <link rel="stylesheet" href="/assets/css/fontawesome.css" /> -->
  <link rel="stylesheet" href="/assets/css/templatemo-eduwell-style.css" />
  <!-- <link rel="stylesheet" href="/assets/css/owl.css" /> -->
  <!-- <link rel="stylesheet" href="/assets/css/lightbox.css" /> -->
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<Header />

<slot name="content" />
