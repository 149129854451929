<script>
  export let subtitle, title;
</script>

<section class="page-heading">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="header-text">
          {#if subtitle}
            <h4>{subtitle}</h4>
          {/if}
          <h1>{title}</h1>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
  h1 {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 700;
  }
  h4 {
    font-family: "Raleway", sans-serif;
    font-weight: 700;
  }
</style>
