<script>
  let widget;
  import MainLayout from "layouts/MainLayout.svelte";
  import PageHeader from "components/page-header/PageHeader.svelte";
  import { _ } from "svelte-i18n";

  // const onSmartWidgetLoad = () => {
  //   widget = window.widget(
  //     {
  //       company_code: "HunaynTech",
  //       bg_color_widget: "#ffffff",
  //       bg_color_headers: "#2693ec",
  //       bg_color_links: "#00a85c33",
  //       txt_color_headers: "#ffffff",
  //       txt_color_job: "#3d3d3d",
  //       bg_color_even_row: "#e0e0e0",
  //       bg_color_odd_row: "#f7f7f7",
  //       auto_width: "auto",
  //       auto_height: "auto",
  //       number: "on",
  //       department: "true",
  //       add_search: "false",
  //       job_title: "true",
  //       type_of_employment: "true",
  //       occupational_area: "false",
  //       location: "false",
  //       dateFormat: "us",
  //       trid: "landing-page",
  //       api_url: "https://www.smartrecruiters.com",
  //       custom_css_url:
  //         "https://static.smartrecruiters.com/job-widget/1.6.1/css/smart_widget.css",
  //     },
  //     "smart-widget"
  //   );
  // };
</script>

<svelte:head>
  <!-- <script
    type="text/javascript"
    on:load={onSmartWidgetLoad}
    src="https://static.smartrecruiters.com/job-widget/1.6.1/script/smart_widget.js"
  ></script> -->
</svelte:head>
<MainLayout>
  <div slot="content">
    <PageHeader
      title={$_("career_header_title")}
      subtitle={$_("career_header_subtitle")}
    />
    <section class="services pt-0" id="services">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <iframe
              title="Hunayn learning page"
              src="https://erp.hunayn.tech"
              frameborder="0"
              style="width:100%; min-height:1500px"
            />
            <!-- <div id="smart-widget" class="job-widget" /> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</MainLayout>

<style>
  section {
    padding: 50px;
    margin-top: 20px;
  }
  .srJobListTitles {
    font-size: 2.2em;
  }
</style>
