<script>
  import Typed from "typed.js";
  import { _ } from "svelte-i18n";
  import { onMount } from "svelte";
  import { onDestroy } from "svelte";
  export let strings;

  let typedObj = null;
  let typedElement = null;

  function throwError(message) {
    throw new TypeError(message);
  }

  function initTypedJS() {
    const $typed = typedElement.querySelector(".typing");

    typedObj = new Typed($typed, $$props);
  }

  onMount(() => {
    initTypedJS();
  });
  onDestroy(() => {
    typedObj.destroy();
  });

  function resetTyped() {
    typedObj.strings = [
      $_("digital_ecosystems") + "!",
      $_("digital_products") + "!",
      $_("digital_society") + "!",
      $_("digital_services") + "!",
    ];
    typedObj.reset();
  }

  $: {
    if (typedElement != null) {
      const typedx = typedElement.querySelector(".typing");

      if (typeof strings != undefined && typeof typedx !== undefined) {
        resetTyped();
      }
    }
  }
</script>

<div class="typed-element" bind:this={typedElement}>
  <em class="typing" />
</div>

<style>
  .typed-element {
    display: flex;
    align-items: center;
  }

  :global(.typed-element .typed-cursor) {
    opacity: 1;
    animation: typedjsBlink 0.7s infinite;
  }

  @keyframes typedjsBlink {
    50% {
      opacity: 0;
    }
  }
</style>
