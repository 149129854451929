<script>
  import { _ } from "svelte-i18n";
  import anime from "animejs/lib/anime.es.js";
  import SvelteTypedJs from "components/typed/SvelteTypedJs.svelte";
  import { onMount } from "svelte";
  onMount(() => {
    anime({
      targets: [".header-text", ".right-image img"],
      opacity: [0, 1],
      translateY: [80, 0],
      easing: "spring(2, 80, 10, 3)",
    });
  });
</script>

<section class="main-banner" id="top">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 align-self-center">
        <div class="header-text">
          <h6 class="">
            {@html $_("welcome")}
          </h6>
          <div class="">
            <h2>
              {@html $_("home_banner_title")}
              <SvelteTypedJs
                strings={[
                  $_("digital_ecosystems") + "!",
                  $_("digital_products") + "!",
                  $_("digital_people") + "!",
                  $_("digital_society") + "!",
                  $_("digital_services") + "!",
                ]}
                loop={true}
                smartBackspace="false"
                typeSpeed={100}
                backSpeed={20}
                backDelay={2000}
              />
            </h2>
          </div>
          <div class="main-button-gradient">
            <div class="">
              <a href="#contact-section">{$_("contact_us")}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="right-image">
          <img
            src="/assets/images/banner-right-image-5.webp"
            alt="banner"
            style="width:100%; height:auto; aspect-ratio:4/3;"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="image-after-container" />
</section>
