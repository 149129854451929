<script>
  import { _ } from "svelte-i18n";
  export let index;
  export let key, titleTx, icon, descriptionTx, alt;
</script>

<div class="col-lg-4 single-block aos-init aos-animate" data-aos="fade-up">
  <div class="service-block">
    <span class="snow-dot" />
    <span class="snow-dot" />
    <span class="snow-dot" />
    <span class="snow-dot" />
    <span class="snow-dot" />
    <span class="snow-dot" />
    <span class="snow-dot" />
    <div class="hover-content" />
    <i class="flaticon-web user" />
    <h5 class="title">
      <span class="pointer-hand">{$_(titleTx)}</span>
    </h5>
    <p>
      {$_(titleTx + "_description")}
    </p>
    <span class="detail-button pointer-hand"
      ><i class="icon-img"
        ><img
          src={icon}
          {alt}
          style="width:100%; height:auto; aspect-ratio:1/1;"
          loading="lazy"
        /></i
      ></span
    >
  </div>
  <!-- /.service-block -->
</div>

<style>
  .icon-img img {
    border-radius: 10%;
    padding: 10px;
    background-color: #ffffff36;
  }
  .service-block .title a {
    font-size: 22px;
    line-height: 32px;
    color: #253d5f;
    margin-bottom: 30px;
  }

  [class^="flaticon-"]:before,
  [class*=" flaticon-"]:before,
  [class^="flaticon-"]:after,
  [class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-size: inherit;
    font-style: normal;
    color: inherit;
  }

  .icon-img {
    float: left;
    width: 100px;
    height: 200px;
    position: absolute;
    top: 50px;
    text-align: center;
  }

  .service-block {
    background: #fff;
    border: 1px solid #eff7ff;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    z-index: 5;
    padding: 180px 30px 25px 40px;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(12, 0, 46, 0.06);
    box-shadow: 0px 2px 10px 0px rgba(12, 0, 46, 0.06);
    border: 1px solid #e6e4e4;
    margin-top: 45px;
    min-height: 335px;
  }

  .service-block:before {
    content: "";
    width: 273px;
    height: 273px;
    background: #00a86b;
    border-radius: 50%;
    position: absolute;
    top: -148px;
    left: -83px;
    transform: scale(0);
  }

  .service-block:hover:before {
    transform: scale(1);
    transition: all 0.5s linear;
    transition-delay: 0.1s;
  }

  .service-block:hover {
    border-color: transparent;
  }

  .service-block .title a {
    font-size: 22px;
    line-height: 32px;
    color: #253d5f;
    margin-bottom: 30px;
  }

  .service-block p {
    color: #737f92;
    padding-bottom: 20px;
  }

  .service-block .detail-button {
    font-size: 40px;
    color: #cad5e5;
  }

  .pointer-hand{
    cursor: pointer;
  }

  .service-block .hover-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #006a4e;
    z-index: -3;
    opacity: 0;
    transition: all 0.3s linear;
  }

  .service-block:hover .hover-content {
    opacity: 1;
  }

  .service-block .hover-content:before {
    content: "";
    width: 493px;
    height: 493px;
    background: #119462;
    border-radius: 50%;
    position: absolute;
    top: -250px;
    left: -180px;
    z-index: 1;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  .service-block:hover .hover-content:before {
    opacity: 1;
    transform: translate(0, 0);
    transition: all 0.9s linear;
  }

  .service-block .hover-content:after {
    content: "";
    width: 602px;
    height: 602px;
    background: #007f5c;
    border-radius: 50%;
    position: absolute;
    top: -196px;
    left: -180px;
    z-index: -1;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  .service-block:hover .hover-content:after {
    opacity: 1;
    transform: translate(0, 0);
    transition: all 1.3s linear;
  }

  .service-block .snow-dot {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    clip-path: polygon(
      50% 0%,
      61% 35%,
      98% 35%,
      68% 57%,
      79% 91%,
      50% 70%,
      21% 91%,
      32% 57%,
      2% 35%,
      39% 35%
    );
    background: rgba(255, 255, 255, 0.5);
    z-index: -1;
  }

  @-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .service-block .snow-dot:nth-child(1) {
    top: 25px;
    left: 41%;
    z-index: 1;
    animation: scale-upOne 1s alternate infinite ease-in;
  }

  .service-block .snow-dot:nth-child(2) {
    top: 25px;
    left: 78%;
    animation: scale-upOne 1.1s alternate infinite ease-in;
  }

  .service-block .snow-dot:nth-child(3) {
    top: 75px;
    left: 61%;
    animation: scale-upOne 0.8s alternate infinite ease-in;
  }

  .service-block .snow-dot:nth-child(4) {
    top: 136px;
    left: 84%;
    animation: scale-upOne 1s alternate infinite ease-in;
  }

  .service-block .snow-dot:nth-child(5) {
    bottom: 35px;
    right: 12%;
    animation: scale-upOne 1.2s alternate infinite ease-in;
  }

  .service-block .snow-dot:nth-child(6) {
    bottom: 80px;
    right: 31%;
    animation: scale-upOne 0.9s alternate infinite ease-in;
  }

  .service-block .snow-dot:nth-child(7) {
    bottom: 40px;
    right: 51%;
    animation: scale-upOne 1s alternate infinite ease-in;
  }

  .service-block:hover .detail-button,
  .service-block:hover .title a,
  .service-block:hover p {
    color: #fff !important;
  }

  /****************animation*************/

  @-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .bg-shape {
    -webkit-animation: rotate-center 5s linear infinite both;
    animation: rotate-center 5s linear infinite both;
    cursor: pointer;
  }

  @-webkit-keyframes scale-upOne {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.2);
      transform: scale(0.2);
    }
  }

  @keyframes scale-upOne {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.2);
      transform: scale(0.2);
    }
  }
</style>
